const initBlock = async () => {
  const sections = Array.from(document.getElementsByClassName('b-featured-case-studies'));

  for (const section of sections) {
    const sliderVideosEl = section.querySelector('.swiper.swiper-videos');
    const sliderContentEl = section.querySelector('.swiper.swiper-content');

    if (!sliderVideosEl && !sliderContentEl) {
      continue;
    }

    const {Swiper} = await import('swiper');
    const {Pagination, Navigation, Scrollbar, Controller} = await import('swiper/modules');

    const sliderVideos = new Swiper(sliderVideosEl, {
      modules: [Controller],
      slidesPerView: 1,
      spaceBetween: 16,
    });

    const sliderContent = new Swiper(sliderContentEl, {
      modules: [Pagination, Navigation, Scrollbar, Controller],
      slidesPerView: 1,
      spaceBetween: 16,
      navigation: {
        nextEl: section.querySelector('.m-slider-nav__nav-next'),
        prevEl: section.querySelector('.m-slider-nav__nav-prev'),
      },
      pagination: {
        el: section.querySelector('.m-slider-nav__fraction'),
        type: 'custom',
        renderCustom: function (swiper, current, total) {
          return current + '/' + total;
        },
      },
      scrollbar: {
        el: section.querySelector('.m-slider-nav__scrollbar'),
        draggable: true,
      },
    });

    sliderVideos.controller.control = sliderContent;
    sliderContent.controller.control = sliderVideos;
  }
};

window.requestAnimationFrame(async function check() {
  document.body ? await initBlock() : window.requestAnimationFrame(check);
});

if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
